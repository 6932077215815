import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';
import GTMData from '@square-enix-private/fe-gtm/dist/GTMData';
import { Flex } from '@square-enix-private/vaquita-ui';
import SectionTitle from '~/components/SectionTitle/index';

const Content = styled(Flex)`
  min-height: 200px;
  max-width: 1024px;
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  margin: auto;
  margin: 0 0 20px;
  padding: 15px;
  background-color: rgb(0, 0, 0, 0.5);
  font-size: 16px;
  text-align: center;
  z-index: 5;

  h3 {
    margin-bottom: 0;
    font-size: 20px;
  }

  img {
    width: 100%;
  }

  a {
    text-decoration: none;
    color: ${({ theme: { colors } }) => colors.text.tertiary};
  }

  li {
    text-align: left;
    word-break: break-word;
  }

  @media screen and (min-width: 581px) {
    max-width: 1024px;
    font-size: 18px;

    h3 {
      font-size: 30px;
    }
  }
`;

const ProductVerification = () => {
  const { t } = useTranslation('contentdocuments');

  return (
    <Flex
      maxWidth="1400px"
      m="auto"
      pt={['xlarge', 'xxlarge']}
      px={['medium', 'large']}
    >
      <Flex
        width="100%"
        alignItems="center"
        flexDirection="column"
        mb={[0, 'medium', 'xlarge']}
      >
        <div>
          <SectionTitle title={t('title')} />
        </div>
        <Content dangerouslySetInnerHTML={{ __html: t('content') }} />
      </Flex>
      <GTMData pageId="ProductVerification" breadcrumb="ProductVerification" />
    </Flex>
  );
};

export const query = graphql`
  query($locale: String) {
    ...FooterData
    ...GenericData
    ...MetadataData
    ...FlagsData
    ...MenuData
    ...AssetsData
    contentdocuments(filter: { localeDirectory: { eq: $locale } }) {
      slug
      seoTitle
      title
      content
    }
  }
`;

export default ProductVerification;
