import React from 'react';
import { Flex } from '@square-enix-private/vaquita-ui';
import PropTypes from 'prop-types';
import { Wrapper, SectionTitle, SectionSubTitle } from './elements';

export const Title = ({ title, children, ...rest }) => (
  <>
    <Wrapper
      justifyContent={['center', null, 'unset']}
      my={[0, null, 'unset']}
      {...rest}
    >
      <Flex flexDirection="column" justifyContent={['center', null, 'unset']}>
        <SectionTitle
          fontSize={[38, 46]}
          textAlign={['center', null, 'left']}
          mx={['auto', 'unset']}
          my={['medium', 'large']}
          px={['55px', 0]}
          upperCase
        >
          {title}
        </SectionTitle>
        <SectionSubTitle
          fontSize={['28px', '26px']}
          textAlign={['center', 'center', 'left']}
          upperCase
        >
          {children}
        </SectionSubTitle>
      </Flex>
    </Wrapper>
  </>
);

Title.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.string
};

Title.defaultProps = {
  children: null
};

export default Title;
